import React from "react";
import styled from "styled-components";

import Button from "@/components/buttons/Button";
import UserAvatar from "@/components/icons/UserAvatar/UserAvatar";
import { P } from "@/components/typography";
import parseImageURL2 from "@/utils/parseImageURL2";

const StyledForumPost = styled.article`
  cursor: pointer;
  border-radius: 8px;
  padding: 24px;
  overflow: hidden;
  position: relative;
  z-index: 1;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: unset;

  @media (max-width: 600px) {
    min-height: unset;
  }

  header {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 20px;
  }

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 5;

    @media (max-width: 600px) {
      margin-top: 20px;
    }

    .likes {
      display: flex;
      gap: 5.55px;
    }

    p {
      margin: 0;
    }
  }
`;

type ChatItem = {
  dir: "to" | "from";
  user_name: string;
  message: string;
};

function ChatBubble({
  from,
  isLeft,
  message,
}: {
  from: string;
  isLeft: boolean;
  message: string;
}) {
  return (
    <div
      className="flex max-w-[85%] flex-1 basis-[85%] flex-col"
      style={{
        alignSelf: isLeft ? "flex-start" : "flex-end",
      }}
    >
      <P
        color="success"
        style={{
          textAlign: isLeft ? "left" : "right",
          margin: 0,
          color: "var(--color-white)",
          fontWeight: 500,
        }}
      >
        {from}
      </P>
      <div className="rounded-lg bg-white p-3 font-[Avenir] font-light text-black">
        <div
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
      </div>
    </div>
  );
}

export default function ChatExampleItem({
  convo,
  buddy,
  name,
  age,
  profilePic,
}: {
  convo?: ChatItem[];
  buddy?: string;
  name?: string;
  age?: string;
  profilePic?: string;
}) {
  return (
    <StyledForumPost className={`with-bg-image bg-orange`}>
      <div>
        <header>
          <UserAvatar
            size="md"
            alt="villa pinedo"
            src={
              profilePic
                ? parseImageURL2(profilePic)
                : "/android-chrome-192x192.png"
            }
          />
          <div>
            <P
              color="success"
              style={{
                margin: 0,
                fontWeight: 700,
                color: "var(--color-white)",
              }}
            >
              {buddy}
            </P>
            <P
              style={{
                margin: 0,
                fontWeight: 300,
                color: "var(--color-white)",
              }}
            >
              {name}, {age} jaar
            </P>
          </div>
        </header>
        <div className="flex flex-col gap-2 overflow-y-auto pb-6">
          {convo !== undefined &&
            convo?.length > 0 &&
            convo?.map((item, index) => (
              <ChatBubble
                key={index}
                from={item.user_name}
                isLeft={item.dir === "from"}
                message={item.message}
              />
            ))}
        </div>
      </div>
      <footer className="chat_btn">
        <Button
          style={{
            fontWeight: `400`,
            fontSize: `18px`,
          }}
          variant="success"
          href="/ik-wil-een-buddy"
        >
          Chat met een buddy
        </Button>
      </footer>
    </StyledForumPost>
  );
}
