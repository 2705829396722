import styled from "styled-components";

export const MasonryGridWrapper = styled.div`
  .front {
    h4,
    p {
      color: var(--color-white) !important;
    }
  }

  .front .main-forum.forum-post {
    background: var(--color-pink);
    width: 100%;
    border: 2px solid var(--color-pink);

    .forum-tags span {
      color: var(--color-white);
    }
  }

  .back .main-forum.forum-post {
    background: var(--color-teal) !important;
    width: 100%;
  }

  .forum-card {
    perspective: 4000px;
    position: relative;
  }

  .new-post-item {
    .grid-item {
      background-image: url(https://admin.villapinedo.nl/assets/3faa0d1e-8c82-4eeb-9f33-a7d494cdf084);
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 8px;

      .profile-meta {
        padding: 16px;
      }

      .custom_new_post {
        max-height: 250px;
        overflow-y: auto !important;
        padding-bottom: 20px;
      }
    }
  }

  .forum-content {
    width: 100%;
    height: 100%;
    box-shadow: none;
    text-align: left;

    transition: transform 1s;
    transform-style: preserve-3d;

    .custom_forum_tags {
      line-height: 1.5;
      color: var(--color-white);
    }
  }

  .forum-card:hover .forum-content {
    transform: rotateY(180deg);
    transition: transform 0.5s;
  }

  .front,
  .back {
    position: absolute;
    height: 100%;
    width: 100%;
    // line-height: 300px;
    font-size: 60px;
    border-radius: 5px;
    backface-visibility: hidden;
  }

  .back .main-forum.forum-post {
    z-index: -1 !important;

    .forum-link:hover {
      button {
        color: var(--color-pink) !important;
      }
    }
  }

  .back {
    h4,
    h3,
    p,
    div {
      color: var(--color-white) !important;
    }

    transform: rotateY(180deg);
  }

  .back .main-forum.forum-post .forum-tags .forum-tag {
    color: var(--color-teal);
    background: var(--color-pink);
  }

  .front .forum-tags span {
    color: var(--color-white);
  }

  .forum-post-2 .front .main-forum.forum-post .forum-tags span {
    color: var(--color-blue);
  }

  .forum-post-2 .back .main-forum.forum-post .forum-tags .forum-tag {
    color: var(--color-teal);
    background: var(--color-blue);
  }

  .forum-post-2 .back .main-forum.forum-post {
    .forum-link {
      &:hover {
        button {
          color: var(--color-blue) !important;
        }
      }
    }
  }

  .front footer {
    color: var(--color-white);
  }

  .back {
    footer {
      display: block;
    }

    .tag {
      color: var(--color-teal) !important;
    }

    .forum-secondary-button {
      color: var(--color-white) !important;
      margin-top: 10px;
    }
  }

  @media (max-width: 768px) {
    .forum-post-2 .back .main-forum.forum-post {
      height: auto !important;
    }
  }

  margin-bottom: 32px;

  --grid-gap: 32px;

  .mason-grid {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: var(--grid-gap);
    gap: var(--grid-gap);

    .grid-item {
      > :first-child {
        min-width: 300px;
      }
    }
  }

  .mason-grid-custom-size {
    display: flex;
    flex-wrap: wrap;
    gap: var(--grid-gap);
    margin-bottom: var(--grid-gap);
  }

  @media ${({ theme }) => theme.devices.tablet} {
    .mason-grid-2,
    .mason-grid-4 {
      .grid-item {
        flex: 1 0 45%;
      }
    }

    .mason-grid-3 {
      .grid-item {
        flex: 1 0 30%;
      }
    }

    .mason-grid-5,
    .mason-grid-6,
    .mason-grid-7,
    .mason-grid-8 {
      flex-flow: row wrap;

      .grid-item {
        flex: 1 0 21%;

        &:nth-child(1) {
          flex: 2 0 55%;
        }
      }
    }

    .mason-grid-5 {
      .grid-item {
        &:nth-child(4n) {
          flex: 2 0 30%;
        }
      }
    }

    .mason-grid-6 {
      .grid-item {
        &:nth-child(3) {
          flex: 2 0 30%;
        }

        &:nth-child(4) {
          flex: 2 0 35%;
        }

        &:nth-child(5) {
          flex: 2 0 35%;
        }
      }
    }

    .mason-grid-7 {
      .grid-item {
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          flex: 2 0 30%;
        }
      }
    }

    .mason-grid-8 {
      .grid-item {
        &:nth-child(4) {
          flex: 2 0 40%;
        }
      }
    }
  }
`;
