import { CircularProgress, Container, Hidden } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { getHomeData, getHomeTopPageData } from "@/api/api";
import { ThemeService } from "@/api/services/theme.service";
import TagList from "@/components/buttons/TagList/TagList";
import ChevronRight from "@/components/icons/ChevronRight/ChevronRight";
import { Footer, Grid, Hero } from "@/components/layout";
import {
  MasonryGrid,
  PageBlockItem,
} from "@/components/layout/MasonryGrid/MasonryGRid2";
import PageWrapper from "@/components/layout/PageWrapper/PageWrappernew";
import ScrollDown from "@/components/layout/ScrollDown/ScrollDown";
import { H4, P, TitleWithHighlights } from "@/components/typography";
import TextWithDropdowns from "@/components/typography/TextWithDropdowns";
import { AudienceType } from "@/enums/audienceType";
import { HomePageProps } from "@/types/pageTypes";
import parseImageURL2 from "@/utils/parseImageURL2";

export const getServerSideProps = async () => {
  const pageReq = await getHomeData();

  const pageRes = await pageReq.json();
  const themes = await ThemeService.getThemesByAudience({
    audienceName: AudienceType.Children,
  });

  return {
    props: {
      pageData: pageRes || null,
      categories: themes,
    },
  };
};

export default function Home({ pageData, categories }: HomePageProps) {
  const currentPage = useRef(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [showTags, setShowTags] = useState(true);
  const [pageBlocks, setPageBlocks] = useState<any[]>([]);
  const [ageCategory, setAgeCategory] = useState("");
  const [category, setCategory] = useState("");
  const [totalPosts, setTotalPosts] = useState(0);
  const [triggerFetch, setTriggerFetch] = useState(false);

  useEffect(() => {
    if (category || ageCategory) {
      currentPage.current = 1;
      setPageBlocks([]);
    }
  }, [category, ageCategory]);

  useEffect(() => {
    const fetchData = async () => {
      const firstData = await getHomeTopPageData({
        ageCategory: ageCategory,
        category: category,
        page: currentPage.current,
      });

      let blocks = [await firstData.json()];
      let postCount = getPostCount(blocks);

      // If postCount from the first request is less than or equal to one, make another request
      if (postCount <= 1) {
        const secondData = await getHomeTopPageData({
          ageCategory: ageCategory,
          category: category,
          page: currentPage.current + 1,
        });

        const secondBlocks = await secondData.json();
        blocks = [
          {
            ...blocks[0],
            blog_quote: blocks[0].blog_quote.concat(secondBlocks.blog_quote),
            forum: blocks[0].forum.concat(secondBlocks.forum),
            videos: blocks[0].videos.concat(secondBlocks.videos),
            instagram: blocks[0].instagram.concat(secondBlocks.instagram),
            chats_item: blocks[0].chats_item.concat(secondBlocks.chats_item),
            audio_item: blocks[0].audio_item.concat(secondBlocks.audio_item),
            assignments: blocks[0].assignments.concat(secondBlocks.assignments),
            letter: blocks[0].letter.concat(secondBlocks.letter),
            total_post_count: secondBlocks.total_post_count,
          },
        ];
        currentPage.current = currentPage.current + 1;
      }

      if (currentPage.current === 1 && category === "" && ageCategory === "") {
        setPageBlocks(blocks);
      } else {
        setPageBlocks((merged) => merged.concat(blocks[0]));
      }

      setTotalPosts(blocks[0].total_post_count);
    };

    fetchData();
  }, [ageCategory, category, triggerFetch]);

  useEffect(() => {
    // process instagram
    const windowObj: any = window;
    if (typeof windowObj.instgrm !== "undefined") {
      windowObj.instgrm.Embeds.process();
    }

    const postCount = getPostCount();

    if (postCount >= totalPosts) {
      setIsEnd(true);
    } else {
      setIsEnd(false);
    }
  }, [pageBlocks]);

  const handleScrollDown = async () => {
    const postCount = getPostCount();
    if (postCount < totalPosts && !isLoading) {
      currentPage.current = currentPage.current + 1;
      setTriggerFetch((prev) => !prev);
    }
  };

  const renderScrollDown = () => {
    return (
      <>
        {!isEnd && !isLoading && (
          <ScrollDown marginTop={94} onClick={handleScrollDown} />
        )}
        <div className="flex items-center justify-center">
          {isLoading && <CircularProgress size={"30px"} />}
          {isEnd && getPostCount() <= 0 && (
            <P color="info">{pageData?.loader_post_message}</P>
          )}
          {isEnd && getPostCount() > 0 && (
            <P color="info">{pageData?.loader_more_post_message}</P>
          )}
        </div>
      </>
    );
  };

  const getPostCount = (blocks?: PageBlockItem[]) => {
    if (!blocks) {
      blocks = pageBlocks;
    }
    let postCount = 0;
    if (!blocks || blocks.length <= 0) {
      return postCount;
    }
    blocks.forEach((pb) => {
      postCount +=
        pb.blog_quote.length +
        pb.forum.length +
        pb.videos.length +
        pb.instagram.length +
        pb.chats_item.length +
        pb.audio_item.length +
        pb.assignments.length +
        pb.letter.length;
    });

    return postCount;
  };

  const handleAgeCategorySelection = (selections: any) => {
    setPageBlocks([]);

    setAgeCategory(selections.firstDropdownCategory);
    setCategory(selections.category);
  };
  return (
    <PageWrapper
      seo={{
        title: pageData?.seo_title,
        description: pageData?.seo_description,
        canonical: "https://www.villapinedo.nl",
        image: pageData?.seo_image
          ? parseImageURL2(pageData?.seo_image)
          : undefined,
      }}
      isHomepage
    >
      <Hero
        center
        imageUrl={parseImageURL2(pageData?.hero_image, 1400)}
        style={{
          minHeight: 649,
          position: "relative",
        }}
        mbgn={parseImageURL2(pageData?.mobile_hero_image)}
        showTags={showTags}
        darkenImage={pageData?.hasDarkOverlayHeaderImage}
        darkenImageMobile={pageData?.hasDarkOverlayHeaderImage}
      >
        <Container>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item xs={12} className="mb-8 text-center md:mb-0">
                  <TitleWithHighlights
                    text={pageData?.page_title ?? ""}
                    color="white"
                    className="items-center leading-[150%] font-light sm:text-center sm:text-[46px] md:leading-[120%] lg:text-[80px]"
                  />
                  <Hidden mdUp>
                    <P color={"white"} className="mt-[-12px] italic">
                      overleven met gescheiden ouders
                    </P>
                  </Hidden>
                </Grid>
                <Grid item xs={12}>
                  <TextWithDropdowns
                    color="white"
                    variant="light"
                    className="pt-4 text-[14px] sm:pt-0 sm:text-[18px] lg:text-[28px]"
                    text={pageData?.page_subtitle ?? ""}
                    textToHighlight={pageData?.highlight_words ?? []}
                    categoriesFirstDropdown={pageData?.age_categories}
                    categories={categories}
                    onChange={handleAgeCategorySelection}
                    valueSecondDropdown={category}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Hero>
      <main style={{ marginBottom: "80px" }}>
        <Container className="max-w-[1384px] px-[13px]">
          <div
            className={
              showTags
                ? "hidden"
                : "relative mt-[-34px] mb-[100px] flex justify-center text-center md:hidden"
            }
            onClick={() => setShowTags(true)}
          >
            <div
              className={
                "bg-teal flex w-[320px] cursor-pointer justify-center rounded-[12px] px-[20px] py-[16px] text-[18px] text-white md:px-[50px]"
              }
            >
              {pageData?.tag_select_subject_title} 👉
            </div>
          </div>
          <div
            className={"relative md:block"}
            style={{
              zIndex: "99",
              transform: "translateY(-50%)",
            }}
          >
            <Container className="max-w-[1384px]">
              <TagList
                tags={categories.map((cat) => ({
                  id: cat.id,
                  name: cat.name,
                  status: cat.status,
                }))}
                selected={category}
                prefix={
                  <H4
                    style={{
                      whiteSpace: "nowrap",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      margin: "0px",
                    }}
                    className="pr-[0px]"
                  >
                    <span
                      style={{ fontFamily: "Fjalla One" }}
                      className="text-[16px] font-[400] md:hidden"
                    >
                      {pageData?.tag_subject_title} 👉
                    </span>
                    <span
                      style={{ fontFamily: "Fjalla One" }}
                      className="hidden font-[400] md:block md:text-[28px]"
                    >
                      {pageData?.tag_subject_title} 👉
                    </span>
                  </H4>
                }
                suffix={<ChevronRight />}
                onSelect={(x: string) => {
                  setCategory(x);
                }}
              />
            </Container>
          </div>
        </Container>
        <MasonryGrid pageBlocksArr={pageBlocks} />
        {renderScrollDown()}
      </main>
      <Footer />
    </PageWrapper>
  );
}
