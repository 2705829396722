import Image from "next/image";
import Link from "next/link";
import React from "react";

import Button from "@/components/buttons/Button";
import Tag from "@/components/buttons/Tag/Tag";
import Card from "@/components/card/Card";
import CardFooter from "@/components/card/CardFooter/CardFooter";
import CardHeader from "@/components/card/CardHeader/CardHeader";
import { H4, P } from "@/components/typography";
import URLS from "@/constants/urls";
import { Category } from "@/types/buddyProgramTypes";
import { truncate } from "@/utils/truncate";

interface TagsProps {
  categories: string[];
}

const Tags = ({ categories }: TagsProps) => (
  <div className={"blog-tags"}>
    {categories &&
      categories.slice(0, 3).map((c) => (
        <Tag
          key={c}
          variant="dark"
          size="m"
          className={`bg-pink inline-block`}
          style={{}}
        >
          <>{c}</>
        </Tag>
      ))}
  </div>
);

interface AssignmentItemProps {
  assignment: {
    image: { id: string };
    title: string;
    slug: string;
    categories: Category[];
    content: string;
  };
}

const AssignmentItem = ({ assignment }: AssignmentItemProps) => {
  const maxImageHeight = `max-h-[200px]`;
  return (
    <Link href={`/assignment/${assignment.slug}`}>
      <Card variant={"assignment"} className={`h-full border-none`}>
        <CardHeader className={maxImageHeight}>
          <Image
            src={`${URLS.CMSKIT}/media/${assignment.image.id}`}
            className={`${maxImageHeight} absolute top-0 left-0 z-0 w-full object-cover`}
            width={345}
            height={295}
            alt={assignment.title}
          />
          <Tags categories={assignment.categories?.map((c) => c.name) ?? []} />
        </CardHeader>
        <CardFooter className={`bg-pink`}>
          <H4 className="m-0 pt-1.5 text-[32px] text-white!">
            {"✍🏻"} {assignment.title}
          </H4>
          <P className="mt-3 mb-8 line-clamp-8 text-white">
            {assignment.content ? truncate(assignment.content, -1) : ""}
          </P>
          <Button
            className={"text-pink mt-auto border-none bg-white"}
            variant={"secondary"}
          >
            {"Lees meer"}
          </Button>
        </CardFooter>
      </Card>
    </Link>
  );
};

export default AssignmentItem;
